import React, { useEffect, useState } from "react";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

import WebbDividerSmall from "./webb-divider-sm";
import {
  AssetsTransfersCancel,
  AssetsTransfersApprove,
  TransfersAssetListIssuer,
} from "../../services/srvc-transfers-assets-realm.js";

const TransfersAssetListWaitModule = () => {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();

  const asset = usxx === "user" ? usrx : temx;
  const [loader, setLoader] = useState(true);

  const [data, setData] = useState();
  const [index, setIndex] = useState(1);
  const [items, setItems] = useState(10);

  const [curr, setCurrentIndex] = useState(1);
  const [next, setNextIndex] = useState();
  const [last, setLastIndex] = useState();

  const [search, setSearch] = useState("draft");

  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  const [text, setText] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await TransfersAssetListIssuer({
          data: {
            user: asset.item,
            index: index,
            items: items,
            filters: { name: search },
          },
        });
        console.log(result);

        if (result.stat) {
          setData(result.data.list);
          setTotal(result.data.count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < result.data.count
                ? index * items
                : result.data.count
            } of ${result.data.count}`
          );
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [search, index, items, refresh]);

  const handleApprove = async (item) => {
    var res = await AssetsTransfersApprove({
      data: {
        item: item,
        status: "2",
      },
    });

    if (res) {
      alert("Transaction approved....");
      setRefresh(!refresh);
    }
  };

  const handleDecline = async (item) => {
    var res = await AssetsTransfersCancel({
      data: {
        item: item,
      },
    });

    if (res) {
      alert("Transaction declined.....");
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <div className="border rounded  border-none mb-4">
        {data &&
          data.map((item, index) => (
            <div key={item?.item}>
              <div
                className="d-flex justify-content-between bg-white p-4 "
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target={`#staticBackdrop${index}`}
              >
                <div>
                  <p
                    className="m-0 text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.user?.name || ""}
                  </p>
                  <p className="m-0 d-none" style={{ fontSize: "11px" }}>
                    {item?.user?.mail || ""}
                  </p>
                  <p className="m-0">{item?.meta?.memo || ""}</p>
                  <p style={{ fontSize: "11px", margin: "0" }}>
                    {new Date(item?.created).toLocaleString(undefined, {
                      hour12: true,
                    })}{" "}
                  </p>
                </div>
                <div>
                  <div>
                    <div className="d-flex  gap-3 align-items-center justify-content-end mb-2">
                      <div className="d-flex gap-1">
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          {item?.sale?.number == 0
                            ? "0.00"
                            : item?.sale?.nmbr || ""}{" "}
                        </p>
                        <p className="m-0">{item?.sale?.tick || ""}</p>
                      </div>
                      <div
                        className={`${
                          item.status == 6 || item.status == "6"
                            ? "bg-success"
                            : item?.status == 3 ||
                              item?.status == "3" ||
                              item?.status == 4 ||
                              item?.status == "4" ||
                              item?.status == 7 ||
                              item?.status == "7" ||
                              item?.status == 8 ||
                              item?.status == "8" ||
                              item?.status == 9 ||
                              item?.status == "9"
                            ? "bg-danger"
                            : "bg-warning"
                        }`}
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id={`staticBackdrop${index}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="staticBackdropLabel">
                        Detail
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="border back-color-wite p-2 px-3 rounded-xd pt-4 ">
                        <div>
                          <div className="d-flex  gap-4  justify-content-between">
                            <p> Name</p>
                            <p
                              style={{
                                minWidth: "50px",
                                marginLeft: "40px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item?.user?.name || ""}
                            </p>
                          </div>
                          <div className="d-flex  gap-4  justify-content-between">
                            <p> Mail</p>
                            <p
                              style={{
                                minWidth: "50px",
                                marginLeft: "40px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item?.user?.mail || ""}
                            </p>
                          </div>
                          <div className="d-flex  gap-4  justify-content-between">
                            <p> ID</p>
                            <p
                              style={{
                                minWidth: "50px",
                                marginLeft: "40px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item?.user?.item || ""}
                            </p>
                          </div>
                          <div className="d-flex  gap-4  justify-content-between">
                            <p> Date</p>
                            <p
                              style={{
                                minWidth: "50px",
                                marginLeft: "40px",
                                wordWrap: "break-word",
                              }}
                            >
                              {new Date(item?.created).toDateString() || ""}
                            </p>
                          </div>
                          <div className="d-flex  gap-4  justify-content-between">
                            <p> Sale</p>
                            <p
                              style={{
                                minWidth: "50px",
                                marginLeft: "40px",
                                wordWrap: "break-word",
                              }}
                            >
                              <p className="m-0">
                                {item?.sale?.nmbr == 0
                                  ? "0.00"
                                  : item?.sale?.nmbr || ""}{" "}
                                {item?.sale?.tick || ""}
                              </p>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Status</p>
                          <p>
                            {item?.status == 1
                              ? "Pending"
                              : item?.status == 6
                              ? "Approved"
                              : "Cancelled"}
                          </p>
                        </div>

                        <div
                          className={`d-flex mt-3 ${
                            item?.status == 1 ? "" : "d-none"
                          }`}
                        >
                          <div className="me-auto">
                            <button
                              onClick={() => {
                                handleDecline(item?.item);
                              }}
                              className="btn btn-outline-danger rounded-xx text-small"
                            >
                              Decline
                            </button>
                          </div>
                          <div className="text-end">
                            <button
                              onClick={() => handleApprove(item?.item)}
                              className="btn btn-outline-success rounded-xx text-small"
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                      <WebbDividerSmall />
                    </div>
                  </div>
                </div>
              </div>
              {/* </Link> */}
              <hr className="m-0" />
            </div>
          ))}
      </div>
      {data?.length === 0 ? (
        <p className="border rounded-xd p-3">No Pending Transfers</p>
      ) : (
        <nav aria-label="Page navigation example">
          <ul
            className="pagination justify-content-between align-items-center
          "
          >
            <li className={`page-item ${curr === 1 ? "disabled" : ""}`}>
              <p
                className="page-link"
                // onClick={() => handlePageChange(curr - 1)}
                href="#"
              >
                <i className="bx bx-chevron-left"></i>
              </p>
            </li>

            <p className="m-0">
              {total == 0 ? total : curr * items + 1 - items} -{" "}
              {curr * items > total ? total : curr * items} of {total}
            </p>

            <li
              className={`page-item align-items-end ${
                curr === count ? "disabled" : ""
              }`}
            >
              <p
                className="page-link"
                // onClick={() => handlePageChange(curr + 1)}
                href="#"
              >
                <i className="bx bx-chevron-right"></i>
              </p>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default TransfersAssetListWaitModule;
