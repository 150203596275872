// routes
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import Main from '../views.xz/main-xz'

// views - home
import HomeUser from '../views.home/home-user'
import HomeTeam from '../views.home/home-team'

import AuthMailCode from '../views.auth/auth-mail-code'
import AuthNext from '../views.auth/auth-next'
import AuthSessionX from '../views.auth/auth-session-x'
import UserOnboardNameModule from '../content/onboard/user-onboard-name'




const routes = [
  { route: "/", content: <Main />, auth: false },

  // home
  { route: "/user/home", content: <HomeUser />, auth: false },
  { route: "/team/home", content: <HomeTeam />, auth: false },
  // transfers

  // onboard
  { route: "/user/onboard", content: <UserOnboardNameModule />, auth: false },

  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: true },
  { route: "/auth/x", content: <AuthSessionX />, auth: true },
];

export default function RouteX () {
  const { user } = useAuth()
  // console.log (user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to='/' replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
